/* tslint:disable */
/* eslint-disable */
/**
 * goal-wear
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CustomTokenObtainPair } from '../models';
// @ts-ignore
import type { GeneralMessage } from '../models';
// @ts-ignore
import type { Logout } from '../models';
// @ts-ignore
import type { ResetPassword } from '../models';
// @ts-ignore
import type { ResetPasswordCodeValidateRequest } from '../models';
// @ts-ignore
import type { ResetPasswordRequest } from '../models';
// @ts-ignore
import type { TokenOutput } from '../models';
// @ts-ignore
import type { TokenRefresh } from '../models';
// @ts-ignore
import type { UpdatePassword } from '../models';
// @ts-ignore
import type { ValidateCodeRequest } from '../models';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Generar codigo de seguridad para el cambio de contraeña
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationGenerateCodeCreate: async (resetPasswordRequest: ResetPasswordRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('authenticationGenerateCodeCreate', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/api/authentication/generate-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Iniciar Sesión
         * @param {CustomTokenObtainPair} customTokenObtainPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginCreate: async (customTokenObtainPair: CustomTokenObtainPair, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customTokenObtainPair' is not null or undefined
            assertParamExists('authenticationLoginCreate', 'customTokenObtainPair', customTokenObtainPair)
            const localVarPath = `/api/authentication/login/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(customTokenObtainPair, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Cerrar sesión
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogoutCreate: async (logout: Logout, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'logout' is not null or undefined
            assertParamExists('authenticationLogoutCreate', 'logout', logout)
            const localVarPath = `/api/authentication/logout/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logout, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * restablecer la contraseña atraves de codigo de seguridad
         * @summary Restablecer la contraseña
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRecoverPasswordCodeCreate: async (resetPassword: ResetPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPassword' is not null or undefined
            assertParamExists('authenticationRecoverPasswordCodeCreate', 'resetPassword', resetPassword)
            const localVarPath = `/api/authentication/recover-password-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRefreshCreate: async (tokenRefresh: TokenRefresh, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tokenRefresh' is not null or undefined
            assertParamExists('authenticationRefreshCreate', 'tokenRefresh', tokenRefresh)
            const localVarPath = `/api/authentication/refresh/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenRefresh, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualizar contraseña
         * @summary Actualizar contraseña
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationUpdatePasswordCreate: async (updatePassword: UpdatePassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePassword' is not null or undefined
            assertParamExists('authenticationUpdatePasswordCreate', 'updatePassword', updatePassword)
            const localVarPath = `/api/authentication/update-password/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePassword, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ResetPasswordCodeValidateRequest} resetPasswordCodeValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationValidateCodeCreate: async (resetPasswordCodeValidateRequest: ResetPasswordCodeValidateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordCodeValidateRequest' is not null or undefined
            assertParamExists('authenticationValidateCodeCreate', 'resetPasswordCodeValidateRequest', resetPasswordCodeValidateRequest)
            const localVarPath = `/api/authentication/validate-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordCodeValidateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Generar codigo de seguridad para el cambio de contraeña
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationGenerateCodeCreate(resetPasswordRequest: ResetPasswordRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationGenerateCodeCreate(resetPasswordRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationGenerateCodeCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Iniciar Sesión
         * @param {CustomTokenObtainPair} customTokenObtainPair 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLoginCreate(customTokenObtainPair: CustomTokenObtainPair, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationLoginCreate(customTokenObtainPair, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationLoginCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Cerrar sesión
         * @param {Logout} logout 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationLogoutCreate(logout: Logout, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationLogoutCreate(logout, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationLogoutCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * restablecer la contraseña atraves de codigo de seguridad
         * @summary Restablecer la contraseña
         * @param {ResetPassword} resetPassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationRecoverPasswordCodeCreate(resetPassword: ResetPassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationRecoverPasswordCodeCreate(resetPassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationRecoverPasswordCodeCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {TokenRefresh} tokenRefresh 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationRefreshCreate(tokenRefresh: TokenRefresh, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenRefresh>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationRefreshCreate(tokenRefresh, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationRefreshCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Actualizar contraseña
         * @summary Actualizar contraseña
         * @param {UpdatePassword} updatePassword 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationUpdatePasswordCreate(updatePassword: UpdatePassword, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GeneralMessage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationUpdatePasswordCreate(updatePassword, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationUpdatePasswordCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ResetPasswordCodeValidateRequest} resetPasswordCodeValidateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async authenticationValidateCodeCreate(resetPasswordCodeValidateRequest: ResetPasswordCodeValidateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateCodeRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.authenticationValidateCodeCreate(resetPasswordCodeValidateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AuthenticationApi.authenticationValidateCodeCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Generar codigo de seguridad para el cambio de contraeña
         * @param {AuthenticationApiAuthenticationGenerateCodeCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationGenerateCodeCreate(requestParameters: AuthenticationApiAuthenticationGenerateCodeCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralMessage> {
            return localVarFp.authenticationGenerateCodeCreate(requestParameters.resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Iniciar Sesión
         * @param {AuthenticationApiAuthenticationLoginCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLoginCreate(requestParameters: AuthenticationApiAuthenticationLoginCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenOutput> {
            return localVarFp.authenticationLoginCreate(requestParameters.customTokenObtainPair, options).then((request) => request(axios, basePath));
        },
        /**
         * Cerrar sesión
         * @param {AuthenticationApiAuthenticationLogoutCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationLogoutCreate(requestParameters: AuthenticationApiAuthenticationLogoutCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.authenticationLogoutCreate(requestParameters.logout, options).then((request) => request(axios, basePath));
        },
        /**
         * restablecer la contraseña atraves de codigo de seguridad
         * @summary Restablecer la contraseña
         * @param {AuthenticationApiAuthenticationRecoverPasswordCodeCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRecoverPasswordCodeCreate(requestParameters: AuthenticationApiAuthenticationRecoverPasswordCodeCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralMessage> {
            return localVarFp.authenticationRecoverPasswordCodeCreate(requestParameters.resetPassword, options).then((request) => request(axios, basePath));
        },
        /**
         * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
         * @param {AuthenticationApiAuthenticationRefreshCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationRefreshCreate(requestParameters: AuthenticationApiAuthenticationRefreshCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TokenRefresh> {
            return localVarFp.authenticationRefreshCreate(requestParameters.tokenRefresh, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualizar contraseña
         * @summary Actualizar contraseña
         * @param {AuthenticationApiAuthenticationUpdatePasswordCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationUpdatePasswordCreate(requestParameters: AuthenticationApiAuthenticationUpdatePasswordCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GeneralMessage> {
            return localVarFp.authenticationUpdatePasswordCreate(requestParameters.updatePassword, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticationApiAuthenticationValidateCodeCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticationValidateCodeCreate(requestParameters: AuthenticationApiAuthenticationValidateCodeCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ValidateCodeRequest> {
            return localVarFp.authenticationValidateCodeCreate(requestParameters.resetPasswordCodeValidateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for authenticationGenerateCodeCreate operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthenticationGenerateCodeCreateRequest
 */
export interface AuthenticationApiAuthenticationGenerateCodeCreateRequest {
    /**
     * 
     * @type {ResetPasswordRequest}
     * @memberof AuthenticationApiAuthenticationGenerateCodeCreate
     */
    readonly resetPasswordRequest: ResetPasswordRequest
}

/**
 * Request parameters for authenticationLoginCreate operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthenticationLoginCreateRequest
 */
export interface AuthenticationApiAuthenticationLoginCreateRequest {
    /**
     * 
     * @type {CustomTokenObtainPair}
     * @memberof AuthenticationApiAuthenticationLoginCreate
     */
    readonly customTokenObtainPair: CustomTokenObtainPair
}

/**
 * Request parameters for authenticationLogoutCreate operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthenticationLogoutCreateRequest
 */
export interface AuthenticationApiAuthenticationLogoutCreateRequest {
    /**
     * 
     * @type {Logout}
     * @memberof AuthenticationApiAuthenticationLogoutCreate
     */
    readonly logout: Logout
}

/**
 * Request parameters for authenticationRecoverPasswordCodeCreate operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthenticationRecoverPasswordCodeCreateRequest
 */
export interface AuthenticationApiAuthenticationRecoverPasswordCodeCreateRequest {
    /**
     * 
     * @type {ResetPassword}
     * @memberof AuthenticationApiAuthenticationRecoverPasswordCodeCreate
     */
    readonly resetPassword: ResetPassword
}

/**
 * Request parameters for authenticationRefreshCreate operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthenticationRefreshCreateRequest
 */
export interface AuthenticationApiAuthenticationRefreshCreateRequest {
    /**
     * 
     * @type {TokenRefresh}
     * @memberof AuthenticationApiAuthenticationRefreshCreate
     */
    readonly tokenRefresh: TokenRefresh
}

/**
 * Request parameters for authenticationUpdatePasswordCreate operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthenticationUpdatePasswordCreateRequest
 */
export interface AuthenticationApiAuthenticationUpdatePasswordCreateRequest {
    /**
     * 
     * @type {UpdatePassword}
     * @memberof AuthenticationApiAuthenticationUpdatePasswordCreate
     */
    readonly updatePassword: UpdatePassword
}

/**
 * Request parameters for authenticationValidateCodeCreate operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiAuthenticationValidateCodeCreateRequest
 */
export interface AuthenticationApiAuthenticationValidateCodeCreateRequest {
    /**
     * 
     * @type {ResetPasswordCodeValidateRequest}
     * @memberof AuthenticationApiAuthenticationValidateCodeCreate
     */
    readonly resetPasswordCodeValidateRequest: ResetPasswordCodeValidateRequest
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Generar codigo de seguridad para el cambio de contraeña
     * @param {AuthenticationApiAuthenticationGenerateCodeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationGenerateCodeCreate(requestParameters: AuthenticationApiAuthenticationGenerateCodeCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationGenerateCodeCreate(requestParameters.resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Iniciar Sesión
     * @param {AuthenticationApiAuthenticationLoginCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLoginCreate(requestParameters: AuthenticationApiAuthenticationLoginCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationLoginCreate(requestParameters.customTokenObtainPair, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Cerrar sesión
     * @param {AuthenticationApiAuthenticationLogoutCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationLogoutCreate(requestParameters: AuthenticationApiAuthenticationLogoutCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationLogoutCreate(requestParameters.logout, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * restablecer la contraseña atraves de codigo de seguridad
     * @summary Restablecer la contraseña
     * @param {AuthenticationApiAuthenticationRecoverPasswordCodeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationRecoverPasswordCodeCreate(requestParameters: AuthenticationApiAuthenticationRecoverPasswordCodeCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationRecoverPasswordCodeCreate(requestParameters.resetPassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Takes a refresh type JSON web token and returns an access type JSON web token if the refresh token is valid.
     * @param {AuthenticationApiAuthenticationRefreshCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationRefreshCreate(requestParameters: AuthenticationApiAuthenticationRefreshCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationRefreshCreate(requestParameters.tokenRefresh, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualizar contraseña
     * @summary Actualizar contraseña
     * @param {AuthenticationApiAuthenticationUpdatePasswordCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationUpdatePasswordCreate(requestParameters: AuthenticationApiAuthenticationUpdatePasswordCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationUpdatePasswordCreate(requestParameters.updatePassword, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiAuthenticationValidateCodeCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public authenticationValidateCodeCreate(requestParameters: AuthenticationApiAuthenticationValidateCodeCreateRequest, options?: RawAxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).authenticationValidateCodeCreate(requestParameters.resetPasswordCodeValidateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

