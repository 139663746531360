import axios from "axios";

const apiUrl = "https://back.goalwear.store";

//const apiUrl = "http://localhost:8000";


const axiosInstance = axios.create({
  baseURL: apiUrl,
  timeout: 10000,
});

export default axiosInstance;