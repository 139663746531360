// src/hooks/useAxiosInterceptor.ts
import { useEffect, useRef } from "react";
import { useLoader } from "../context/LoaderContext";
import setup from "../api/interceptor";

export const useAxiosInterceptors = () => {
  const loader = useLoader();
  const isInitialized = useRef(false); // Controla la inicialización única

  useEffect(() => {
    if (!isInitialized.current) {
      setup({ loader });
      isInitialized.current = true;
    }
  }, [loader]);
};
