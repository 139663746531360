/* tslint:disable */
/* eslint-disable */
/**
 * goal-wear
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AddToCart } from '../models';
// @ts-ignore
import type { Cart } from '../models';
// @ts-ignore
import type { PatchedCart } from '../models';
/**
 * CartApi - axios parameter creator
 * @export
 */
export const CartApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AddToCart} addToCart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartCreate: async (addToCart: AddToCart, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addToCart' is not null or undefined
            assertParamExists('shoppingCarCartCreate', 'addToCart', addToCart)
            const localVarPath = `/api/shopping-car/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addToCart, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartDestroy: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shopping-car/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PatchedCart} [patchedCart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartPartialUpdate: async (patchedCart?: PatchedCart, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shopping-car/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedCart, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shopping-car/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartRetrieve2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('shoppingCarCartRetrieve2', 'id', id)
            const localVarPath = `/api/shopping-car/cart/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Cart} [cart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartUpdate: async (cart?: Cart, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shopping-car/cart/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cart, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CartApi - functional programming interface
 * @export
 */
export const CartApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CartApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AddToCart} addToCart 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingCarCartCreate(addToCart: AddToCart, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingCarCartCreate(addToCart, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CartApi.shoppingCarCartCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingCarCartDestroy(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingCarCartDestroy(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CartApi.shoppingCarCartDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PatchedCart} [patchedCart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingCarCartPartialUpdate(patchedCart?: PatchedCart, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingCarCartPartialUpdate(patchedCart, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CartApi.shoppingCarCartPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingCarCartRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingCarCartRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CartApi.shoppingCarCartRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingCarCartRetrieve2(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingCarCartRetrieve2(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CartApi.shoppingCarCartRetrieve2']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Cart} [cart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shoppingCarCartUpdate(cart?: Cart, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Cart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shoppingCarCartUpdate(cart, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CartApi.shoppingCarCartUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CartApi - factory interface
 * @export
 */
export const CartApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CartApiFp(configuration)
    return {
        /**
         * 
         * @param {CartApiShoppingCarCartCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartCreate(requestParameters: CartApiShoppingCarCartCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Cart> {
            return localVarFp.shoppingCarCartCreate(requestParameters.addToCart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartDestroy(options?: RawAxiosRequestConfig): AxiosPromise<Cart> {
            return localVarFp.shoppingCarCartDestroy(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CartApiShoppingCarCartPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartPartialUpdate(requestParameters: CartApiShoppingCarCartPartialUpdateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Cart> {
            return localVarFp.shoppingCarCartPartialUpdate(requestParameters.patchedCart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<Cart> {
            return localVarFp.shoppingCarCartRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CartApiShoppingCarCartRetrieve2Request} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartRetrieve2(requestParameters: CartApiShoppingCarCartRetrieve2Request, options?: RawAxiosRequestConfig): AxiosPromise<Cart> {
            return localVarFp.shoppingCarCartRetrieve2(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CartApiShoppingCarCartUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shoppingCarCartUpdate(requestParameters: CartApiShoppingCarCartUpdateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Cart> {
            return localVarFp.shoppingCarCartUpdate(requestParameters.cart, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for shoppingCarCartCreate operation in CartApi.
 * @export
 * @interface CartApiShoppingCarCartCreateRequest
 */
export interface CartApiShoppingCarCartCreateRequest {
    /**
     * 
     * @type {AddToCart}
     * @memberof CartApiShoppingCarCartCreate
     */
    readonly addToCart: AddToCart
}

/**
 * Request parameters for shoppingCarCartPartialUpdate operation in CartApi.
 * @export
 * @interface CartApiShoppingCarCartPartialUpdateRequest
 */
export interface CartApiShoppingCarCartPartialUpdateRequest {
    /**
     * 
     * @type {PatchedCart}
     * @memberof CartApiShoppingCarCartPartialUpdate
     */
    readonly patchedCart?: PatchedCart
}

/**
 * Request parameters for shoppingCarCartRetrieve2 operation in CartApi.
 * @export
 * @interface CartApiShoppingCarCartRetrieve2Request
 */
export interface CartApiShoppingCarCartRetrieve2Request {
    /**
     * 
     * @type {number}
     * @memberof CartApiShoppingCarCartRetrieve2
     */
    readonly id: number
}

/**
 * Request parameters for shoppingCarCartUpdate operation in CartApi.
 * @export
 * @interface CartApiShoppingCarCartUpdateRequest
 */
export interface CartApiShoppingCarCartUpdateRequest {
    /**
     * 
     * @type {Cart}
     * @memberof CartApiShoppingCarCartUpdate
     */
    readonly cart?: Cart
}

/**
 * CartApi - object-oriented interface
 * @export
 * @class CartApi
 * @extends {BaseAPI}
 */
export class CartApi extends BaseAPI {
    /**
     * 
     * @param {CartApiShoppingCarCartCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public shoppingCarCartCreate(requestParameters: CartApiShoppingCarCartCreateRequest, options?: RawAxiosRequestConfig) {
        return CartApiFp(this.configuration).shoppingCarCartCreate(requestParameters.addToCart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public shoppingCarCartDestroy(options?: RawAxiosRequestConfig) {
        return CartApiFp(this.configuration).shoppingCarCartDestroy(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartApiShoppingCarCartPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public shoppingCarCartPartialUpdate(requestParameters: CartApiShoppingCarCartPartialUpdateRequest = {}, options?: RawAxiosRequestConfig) {
        return CartApiFp(this.configuration).shoppingCarCartPartialUpdate(requestParameters.patchedCart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public shoppingCarCartRetrieve(options?: RawAxiosRequestConfig) {
        return CartApiFp(this.configuration).shoppingCarCartRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartApiShoppingCarCartRetrieve2Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public shoppingCarCartRetrieve2(requestParameters: CartApiShoppingCarCartRetrieve2Request, options?: RawAxiosRequestConfig) {
        return CartApiFp(this.configuration).shoppingCarCartRetrieve2(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CartApiShoppingCarCartUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CartApi
     */
    public shoppingCarCartUpdate(requestParameters: CartApiShoppingCarCartUpdateRequest = {}, options?: RawAxiosRequestConfig) {
        return CartApiFp(this.configuration).shoppingCarCartUpdate(requestParameters.cart, options).then((request) => request(this.axios, this.basePath));
    }
}

