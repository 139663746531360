// src/api/index.ts
import { AuthenticationApi, BannersApi, CategoriesApi, ProductsApi, PaymentsApi, CartApi, SettingsApi, UsersApi, ShippingApi, PublicityApi, 
    

} from '../api-client/';
import axiosInstance from "./axios";
const apiUrl = "https://goal-wear.dev.byteobe.com";

export const authenticateApi = new AuthenticationApi(undefined, apiUrl, axiosInstance);
export const bannersApi = new BannersApi(undefined, apiUrl, axiosInstance);
export const categoriesApi = new CategoriesApi(undefined, apiUrl, axiosInstance);
export const productsApi = new ProductsApi(undefined, apiUrl, axiosInstance);
export const paymentApi = new PaymentsApi(undefined, apiUrl, axiosInstance);
export const cartApi = new CartApi(undefined, apiUrl, axiosInstance);
export const settingApi = new SettingsApi(undefined, apiUrl, axiosInstance);
export const usersApi = new UsersApi(undefined, apiUrl, axiosInstance);
export const shippingAPi = new ShippingApi(undefined, apiUrl, axiosInstance);
export const publicityApi = new PublicityApi(undefined, apiUrl, axiosInstance);