/* tslint:disable */
/* eslint-disable */
/**
 * goal-wear
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreateOrder } from '../models';
// @ts-ignore
import type { TransactionBuy } from '../models';
// @ts-ignore
import type { ValidateOrder } from '../models';
/**
 * PaymentsApi - axios parameter creator
 * @export
 */
export const PaymentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateOrder} createOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCreateOrdersCreate: async (createOrder: CreateOrder, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrder' is not null or undefined
            assertParamExists('paymentsCreateOrdersCreate', 'createOrder', createOrder)
            const localVarPath = `/api/payments/create-orders/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsTransactionListList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/payments/transaction-list/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateOrder} validateOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsValidateOrderCreate: async (validateOrder: ValidateOrder, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateOrder' is not null or undefined
            assertParamExists('paymentsValidateOrderCreate', 'validateOrder', validateOrder)
            const localVarPath = `/api/payments/validate-order/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateOrder, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentsApi - functional programming interface
 * @export
 */
export const PaymentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PaymentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateOrder} createOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsCreateOrdersCreate(createOrder: CreateOrder, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: any; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsCreateOrdersCreate(createOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.paymentsCreateOrdersCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsTransactionListList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionBuy>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsTransactionListList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.paymentsTransactionListList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ValidateOrder} validateOrder 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async paymentsValidateOrderCreate(validateOrder: ValidateOrder, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.paymentsValidateOrderCreate(validateOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PaymentsApi.paymentsValidateOrderCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PaymentsApi - factory interface
 * @export
 */
export const PaymentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PaymentsApiFp(configuration)
    return {
        /**
         * 
         * @param {PaymentsApiPaymentsCreateOrdersCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsCreateOrdersCreate(requestParameters: PaymentsApiPaymentsCreateOrdersCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<{ [key: string]: any; }> {
            return localVarFp.paymentsCreateOrdersCreate(requestParameters.createOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsTransactionListList(options?: RawAxiosRequestConfig): AxiosPromise<Array<TransactionBuy>> {
            return localVarFp.paymentsTransactionListList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentsApiPaymentsValidateOrderCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        paymentsValidateOrderCreate(requestParameters: PaymentsApiPaymentsValidateOrderCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.paymentsValidateOrderCreate(requestParameters.validateOrder, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for paymentsCreateOrdersCreate operation in PaymentsApi.
 * @export
 * @interface PaymentsApiPaymentsCreateOrdersCreateRequest
 */
export interface PaymentsApiPaymentsCreateOrdersCreateRequest {
    /**
     * 
     * @type {CreateOrder}
     * @memberof PaymentsApiPaymentsCreateOrdersCreate
     */
    readonly createOrder: CreateOrder
}

/**
 * Request parameters for paymentsValidateOrderCreate operation in PaymentsApi.
 * @export
 * @interface PaymentsApiPaymentsValidateOrderCreateRequest
 */
export interface PaymentsApiPaymentsValidateOrderCreateRequest {
    /**
     * 
     * @type {ValidateOrder}
     * @memberof PaymentsApiPaymentsValidateOrderCreate
     */
    readonly validateOrder: ValidateOrder
}

/**
 * PaymentsApi - object-oriented interface
 * @export
 * @class PaymentsApi
 * @extends {BaseAPI}
 */
export class PaymentsApi extends BaseAPI {
    /**
     * 
     * @param {PaymentsApiPaymentsCreateOrdersCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsCreateOrdersCreate(requestParameters: PaymentsApiPaymentsCreateOrdersCreateRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsCreateOrdersCreate(requestParameters.createOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsTransactionListList(options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsTransactionListList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PaymentsApiPaymentsValidateOrderCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentsApi
     */
    public paymentsValidateOrderCreate(requestParameters: PaymentsApiPaymentsValidateOrderCreateRequest, options?: RawAxiosRequestConfig) {
        return PaymentsApiFp(this.configuration).paymentsValidateOrderCreate(requestParameters.validateOrder, options).then((request) => request(this.axios, this.basePath));
    }
}

