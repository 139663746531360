import React from 'react';

const EnhancedSpinner = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="relative w-24 h-24">
        <div className="absolute inset-2 border-4 border-transparent rounded-full animate-spin-slow">
          <div className="absolute inset-0 border-4 border-red-700 border-dashed rounded-full"></div>
        </div>
      </div>
    </div>
  );
};

export default EnhancedSpinner;
