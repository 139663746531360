/* tslint:disable */
/* eslint-disable */
/**
 * goal-wear
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Register } from '../models';
// @ts-ignore
import type { User } from '../models';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Obtiene el Usuario Actual utilizando el token en el HEADER
         * @summary Obtiene el Usuario Actual utilizando el token en el HEADER
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentRetrieve: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/current/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Registrar un nuevo Usuario
         * @summary Registrar un nuevo Usuario
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRegisterCreate: async (register: Register, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'register' is not null or undefined
            assertParamExists('usersRegisterCreate', 'register', register)
            const localVarPath = `/api/users/register/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(register, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza la imagen del perfil de usuario
         * @summary Actualiza la imagen de usuario
         * @param {File} [avatar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUploadImageUpdate: async (avatar?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/upload-image/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (avatar !== undefined) { 
                localVarFormParams.append('avatar', avatar as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Actualiza los datos del usuario, solo el usuario puede actualizar sus datos
         * @param {string} username 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserCreate: async (username: string, firstName?: string, lastName?: string, email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersUserCreate', 'username', username)
            const localVarPath = `/api/users/user/{username}/`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (firstName !== undefined) { 
                localVarFormParams.append('first_name', firstName as any);
            }
    
            if (lastName !== undefined) { 
                localVarFormParams.append('last_name', lastName as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Elimina un usuario, solo el usuario se puede eliminar a si mismo
         * @summary Elimina un usuario, solo el usuario se puede eliminar a si mismo
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserDestroy: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersUserDestroy', 'username', username)
            const localVarPath = `/api/users/user/{username}/`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtiene la información de un usuario mediante el nombre usuario
         * @summary Obtiene la información de un usuario mediante el nombre usuario
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserRetrieve: async (username: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('usersUserRetrieve', 'username', username)
            const localVarPath = `/api/users/user/{username}/`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * Obtiene el Usuario Actual utilizando el token en el HEADER
         * @summary Obtiene el Usuario Actual utilizando el token en el HEADER
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersCurrentRetrieve(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersCurrentRetrieve(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersCurrentRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Registrar un nuevo Usuario
         * @summary Registrar un nuevo Usuario
         * @param {Register} register 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersRegisterCreate(register: Register, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersRegisterCreate(register, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersRegisterCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Actualiza la imagen del perfil de usuario
         * @summary Actualiza la imagen de usuario
         * @param {File} [avatar] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUploadImageUpdate(avatar?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUploadImageUpdate(avatar, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUploadImageUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Actualiza los datos del usuario, solo el usuario puede actualizar sus datos
         * @param {string} username 
         * @param {string} [firstName] 
         * @param {string} [lastName] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserCreate(username: string, firstName?: string, lastName?: string, email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserCreate(username, firstName, lastName, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Elimina un usuario, solo el usuario se puede eliminar a si mismo
         * @summary Elimina un usuario, solo el usuario se puede eliminar a si mismo
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserDestroy(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserDestroy(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Obtiene la información de un usuario mediante el nombre usuario
         * @summary Obtiene la información de un usuario mediante el nombre usuario
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserRetrieve(username: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserRetrieve(username, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.usersUserRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * Obtiene el Usuario Actual utilizando el token en el HEADER
         * @summary Obtiene el Usuario Actual utilizando el token en el HEADER
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersCurrentRetrieve(options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersCurrentRetrieve(options).then((request) => request(axios, basePath));
        },
        /**
         * Registrar un nuevo Usuario
         * @summary Registrar un nuevo Usuario
         * @param {UsersApiUsersRegisterCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersRegisterCreate(requestParameters: UsersApiUsersRegisterCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersRegisterCreate(requestParameters.register, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza la imagen del perfil de usuario
         * @summary Actualiza la imagen de usuario
         * @param {UsersApiUsersUploadImageUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUploadImageUpdate(requestParameters: UsersApiUsersUploadImageUpdateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersUploadImageUpdate(requestParameters.avatar, options).then((request) => request(axios, basePath));
        },
        /**
         * Actualiza los datos del usuario, solo el usuario puede actualizar sus datos
         * @param {UsersApiUsersUserCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserCreate(requestParameters: UsersApiUsersUserCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersUserCreate(requestParameters.username, requestParameters.firstName, requestParameters.lastName, requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * Elimina un usuario, solo el usuario se puede eliminar a si mismo
         * @summary Elimina un usuario, solo el usuario se puede eliminar a si mismo
         * @param {UsersApiUsersUserDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserDestroy(requestParameters: UsersApiUsersUserDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.usersUserDestroy(requestParameters.username, options).then((request) => request(axios, basePath));
        },
        /**
         * Obtiene la información de un usuario mediante el nombre usuario
         * @summary Obtiene la información de un usuario mediante el nombre usuario
         * @param {UsersApiUsersUserRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserRetrieve(requestParameters: UsersApiUsersUserRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<User> {
            return localVarFp.usersUserRetrieve(requestParameters.username, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usersRegisterCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersRegisterCreateRequest
 */
export interface UsersApiUsersRegisterCreateRequest {
    /**
     * 
     * @type {Register}
     * @memberof UsersApiUsersRegisterCreate
     */
    readonly register: Register
}

/**
 * Request parameters for usersUploadImageUpdate operation in UsersApi.
 * @export
 * @interface UsersApiUsersUploadImageUpdateRequest
 */
export interface UsersApiUsersUploadImageUpdateRequest {
    /**
     * 
     * @type {File}
     * @memberof UsersApiUsersUploadImageUpdate
     */
    readonly avatar?: File
}

/**
 * Request parameters for usersUserCreate operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserCreateRequest
 */
export interface UsersApiUsersUserCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersUserCreate
     */
    readonly username: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersUserCreate
     */
    readonly firstName?: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersUserCreate
     */
    readonly lastName?: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersUserCreate
     */
    readonly email?: string
}

/**
 * Request parameters for usersUserDestroy operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserDestroyRequest
 */
export interface UsersApiUsersUserDestroyRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersUserDestroy
     */
    readonly username: string
}

/**
 * Request parameters for usersUserRetrieve operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserRetrieveRequest
 */
export interface UsersApiUsersUserRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersUserRetrieve
     */
    readonly username: string
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * Obtiene el Usuario Actual utilizando el token en el HEADER
     * @summary Obtiene el Usuario Actual utilizando el token en el HEADER
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersCurrentRetrieve(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersCurrentRetrieve(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Registrar un nuevo Usuario
     * @summary Registrar un nuevo Usuario
     * @param {UsersApiUsersRegisterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersRegisterCreate(requestParameters: UsersApiUsersRegisterCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersRegisterCreate(requestParameters.register, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza la imagen del perfil de usuario
     * @summary Actualiza la imagen de usuario
     * @param {UsersApiUsersUploadImageUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUploadImageUpdate(requestParameters: UsersApiUsersUploadImageUpdateRequest = {}, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUploadImageUpdate(requestParameters.avatar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Actualiza los datos del usuario, solo el usuario puede actualizar sus datos
     * @param {UsersApiUsersUserCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserCreate(requestParameters: UsersApiUsersUserCreateRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserCreate(requestParameters.username, requestParameters.firstName, requestParameters.lastName, requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Elimina un usuario, solo el usuario se puede eliminar a si mismo
     * @summary Elimina un usuario, solo el usuario se puede eliminar a si mismo
     * @param {UsersApiUsersUserDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserDestroy(requestParameters: UsersApiUsersUserDestroyRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserDestroy(requestParameters.username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Obtiene la información de un usuario mediante el nombre usuario
     * @summary Obtiene la información de un usuario mediante el nombre usuario
     * @param {UsersApiUsersUserRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserRetrieve(requestParameters: UsersApiUsersUserRetrieveRequest, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserRetrieve(requestParameters.username, options).then((request) => request(this.axios, this.basePath));
    }
}

