import { fetchProducts } from "../api/products";
import { useState, useEffect, useCallback } from "react";
import { PaginatedProductList } from '../api-client/'


export const useProducts = (news: boolean) => {
    const [products, setProducts] = useState<PaginatedProductList>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchProductsData = useCallback(async () => {
        setLoading(true);
        try {
            const data = await fetchProducts(false);
            setProducts(data);
        } catch (error:any) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchProductsData();
    }, [fetchProductsData]);

    return { products, loading, error };
};