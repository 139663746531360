/* tslint:disable */
/* eslint-disable */
/**
 * goal-wear
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Banner } from '../models';
/**
 * BannersApi - axios parameter creator
 * @export
 */
export const BannersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {boolean} [active] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityBannersCreate: async (active?: boolean, startDate?: string, endDate?: string, image?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publicity/banners/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (active !== undefined) { 
                localVarFormParams.append('active', String(active) as any);
            }
    
            if (startDate !== undefined) { 
                localVarFormParams.append('start_date', startDate as any);
            }
    
            if (endDate !== undefined) { 
                localVarFormParams.append('end_date', endDate as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityBannersList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publicity/banners/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication tokenAuth required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BannersApi - functional programming interface
 * @export
 */
export const BannersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BannersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {boolean} [active] 
         * @param {string} [startDate] 
         * @param {string} [endDate] 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicityBannersCreate(active?: boolean, startDate?: string, endDate?: string, image?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Banner>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicityBannersCreate(active, startDate, endDate, image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BannersApi.publicityBannersCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicityBannersList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Banner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicityBannersList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BannersApi.publicityBannersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BannersApi - factory interface
 * @export
 */
export const BannersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BannersApiFp(configuration)
    return {
        /**
         * 
         * @param {BannersApiPublicityBannersCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityBannersCreate(requestParameters: BannersApiPublicityBannersCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Banner> {
            return localVarFp.publicityBannersCreate(requestParameters.active, requestParameters.startDate, requestParameters.endDate, requestParameters.image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityBannersList(options?: RawAxiosRequestConfig): AxiosPromise<Array<Banner>> {
            return localVarFp.publicityBannersList(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for publicityBannersCreate operation in BannersApi.
 * @export
 * @interface BannersApiPublicityBannersCreateRequest
 */
export interface BannersApiPublicityBannersCreateRequest {
    /**
     * 
     * @type {boolean}
     * @memberof BannersApiPublicityBannersCreate
     */
    readonly active?: boolean

    /**
     * 
     * @type {string}
     * @memberof BannersApiPublicityBannersCreate
     */
    readonly startDate?: string

    /**
     * 
     * @type {string}
     * @memberof BannersApiPublicityBannersCreate
     */
    readonly endDate?: string

    /**
     * 
     * @type {File}
     * @memberof BannersApiPublicityBannersCreate
     */
    readonly image?: File
}

/**
 * BannersApi - object-oriented interface
 * @export
 * @class BannersApi
 * @extends {BaseAPI}
 */
export class BannersApi extends BaseAPI {
    /**
     * 
     * @param {BannersApiPublicityBannersCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannersApi
     */
    public publicityBannersCreate(requestParameters: BannersApiPublicityBannersCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return BannersApiFp(this.configuration).publicityBannersCreate(requestParameters.active, requestParameters.startDate, requestParameters.endDate, requestParameters.image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BannersApi
     */
    public publicityBannersList(options?: RawAxiosRequestConfig) {
        return BannersApiFp(this.configuration).publicityBannersList(options).then((request) => request(this.axios, this.basePath));
    }
}

