/* tslint:disable */
/* eslint-disable */
/**
 * goal-wear
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Category } from '../models';
// @ts-ignore
import type { PatchedCategory } from '../models';
/**
 * CategoriesApi - axios parameter creator
 * @export
 */
export const CategoriesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {File} [icon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoriesCreate: async (name?: string, icon?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories/categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (icon !== undefined) { 
                localVarFormParams.append('icon', icon as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoriesList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/categories/categories/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesCategoryDestroy', 'id', id)
            const localVarPath = `/api/categories/category/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedCategory} [patchedCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryPartialUpdate: async (id: number, patchedCategory?: PatchedCategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesCategoryPartialUpdate', 'id', id)
            const localVarPath = `/api/categories/category/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedCategory, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesCategoryRetrieve', 'id', id)
            const localVarPath = `/api/categories/category/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Category} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryUpdate: async (id: number, category: Category, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('categoriesCategoryUpdate', 'id', id)
            // verify required parameter 'category' is not null or undefined
            assertParamExists('categoriesCategoryUpdate', 'category', category)
            const localVarPath = `/api/categories/category/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(category, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoriesApi - functional programming interface
 * @export
 */
export const CategoriesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoriesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {File} [icon] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCategoriesCreate(name?: string, icon?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCategoriesCreate(name, icon, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesCategoriesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCategoriesList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCategoriesList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesCategoriesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCategoryDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCategoryDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesCategoryDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedCategory} [patchedCategory] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCategoryPartialUpdate(id: number, patchedCategory?: PatchedCategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCategoryPartialUpdate(id, patchedCategory, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesCategoryPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCategoryRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCategoryRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesCategoryRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Category} category 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async categoriesCategoryUpdate(id: number, category: Category, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.categoriesCategoryUpdate(id, category, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CategoriesApi.categoriesCategoryUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CategoriesApi - factory interface
 * @export
 */
export const CategoriesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoriesApiFp(configuration)
    return {
        /**
         * 
         * @param {CategoriesApiCategoriesCategoriesCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoriesCreate(requestParameters: CategoriesApiCategoriesCategoriesCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.categoriesCategoriesCreate(requestParameters.name, requestParameters.icon, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoriesList(options?: RawAxiosRequestConfig): AxiosPromise<Array<Category>> {
            return localVarFp.categoriesCategoriesList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CategoriesApiCategoriesCategoryDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryDestroy(requestParameters: CategoriesApiCategoriesCategoryDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.categoriesCategoryDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CategoriesApiCategoriesCategoryPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryPartialUpdate(requestParameters: CategoriesApiCategoriesCategoryPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.categoriesCategoryPartialUpdate(requestParameters.id, requestParameters.patchedCategory, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CategoriesApiCategoriesCategoryRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryRetrieve(requestParameters: CategoriesApiCategoriesCategoryRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.categoriesCategoryRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CategoriesApiCategoriesCategoryUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        categoriesCategoryUpdate(requestParameters: CategoriesApiCategoriesCategoryUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.categoriesCategoryUpdate(requestParameters.id, requestParameters.category, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for categoriesCategoriesCreate operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesCategoriesCreateRequest
 */
export interface CategoriesApiCategoriesCategoriesCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof CategoriesApiCategoriesCategoriesCreate
     */
    readonly name?: string

    /**
     * 
     * @type {File}
     * @memberof CategoriesApiCategoriesCategoriesCreate
     */
    readonly icon?: File
}

/**
 * Request parameters for categoriesCategoryDestroy operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesCategoryDestroyRequest
 */
export interface CategoriesApiCategoriesCategoryDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof CategoriesApiCategoriesCategoryDestroy
     */
    readonly id: number
}

/**
 * Request parameters for categoriesCategoryPartialUpdate operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesCategoryPartialUpdateRequest
 */
export interface CategoriesApiCategoriesCategoryPartialUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof CategoriesApiCategoriesCategoryPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedCategory}
     * @memberof CategoriesApiCategoriesCategoryPartialUpdate
     */
    readonly patchedCategory?: PatchedCategory
}

/**
 * Request parameters for categoriesCategoryRetrieve operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesCategoryRetrieveRequest
 */
export interface CategoriesApiCategoriesCategoryRetrieveRequest {
    /**
     * 
     * @type {number}
     * @memberof CategoriesApiCategoriesCategoryRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for categoriesCategoryUpdate operation in CategoriesApi.
 * @export
 * @interface CategoriesApiCategoriesCategoryUpdateRequest
 */
export interface CategoriesApiCategoriesCategoryUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof CategoriesApiCategoriesCategoryUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Category}
     * @memberof CategoriesApiCategoriesCategoryUpdate
     */
    readonly category: Category
}

/**
 * CategoriesApi - object-oriented interface
 * @export
 * @class CategoriesApi
 * @extends {BaseAPI}
 */
export class CategoriesApi extends BaseAPI {
    /**
     * 
     * @param {CategoriesApiCategoriesCategoriesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesCategoriesCreate(requestParameters: CategoriesApiCategoriesCategoriesCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesCategoriesCreate(requestParameters.name, requestParameters.icon, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesCategoriesList(options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesCategoriesList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesCategoryDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesCategoryDestroy(requestParameters: CategoriesApiCategoriesCategoryDestroyRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesCategoryDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesCategoryPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesCategoryPartialUpdate(requestParameters: CategoriesApiCategoriesCategoryPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesCategoryPartialUpdate(requestParameters.id, requestParameters.patchedCategory, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesCategoryRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesCategoryRetrieve(requestParameters: CategoriesApiCategoriesCategoryRetrieveRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesCategoryRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CategoriesApiCategoriesCategoryUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoriesApi
     */
    public categoriesCategoryUpdate(requestParameters: CategoriesApiCategoriesCategoryUpdateRequest, options?: RawAxiosRequestConfig) {
        return CategoriesApiFp(this.configuration).categoriesCategoryUpdate(requestParameters.id, requestParameters.category, options).then((request) => request(this.axios, this.basePath));
    }
}

