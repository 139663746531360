/* tslint:disable */
/* eslint-disable */
/**
 * goal-wear
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { CreateDistributionRequest } from '../models';
// @ts-ignore
import type { Image } from '../models';
// @ts-ignore
import type { PaginatedProductList } from '../models';
// @ts-ignore
import type { PatchedUpdateProductItem } from '../models';
// @ts-ignore
import type { PatchedUpdateProductWithDistribution } from '../models';
// @ts-ignore
import type { Product } from '../models';
// @ts-ignore
import type { ProductDistribution } from '../models';
// @ts-ignore
import type { ProductItem } from '../models';
// @ts-ignore
import type { ProductsProductItemsRemoveImagesCreateRequest } from '../models';
// @ts-ignore
import type { UpdateProductItem } from '../models';
// @ts-ignore
import type { UpdateProductWithDistribution } from '../models';
/**
 * ProductsApi - axios parameter creator
 * @export
 */
export const ProductsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {number} [price] 
         * @param {number} [category] 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreateCreate: async (name?: string, description?: string, price?: number, category?: number, image?: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/create/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (description !== undefined) { 
                localVarFormParams.append('description', description as any);
            }
    
            if (price !== undefined) { 
                localVarFormParams.append('price', price as any);
            }
    
            if (category !== undefined) { 
                localVarFormParams.append('category', category as any);
            }
    
            if (image !== undefined) { 
                localVarFormParams.append('image', image as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateDistributionRequest} createDistributionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreateDistributionCreate: async (createDistributionRequest: CreateDistributionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDistributionRequest' is not null or undefined
            assertParamExists('productsCreateDistributionCreate', 'createDistributionRequest', createDistributionRequest)
            const localVarPath = `/api/products/create-distribution/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDistributionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [_new] Filter for new products (optional)
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsList: async (_new?: boolean, page?: number, pageSize?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (_new !== undefined) {
                localVarQueryParameter['new'] = _new;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsListDistributionsList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/list-distributions/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [distribution] 
         * @param {number} [product] 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductItemCreate: async (distribution?: number, product?: number, images?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/product-item/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (distribution !== undefined) { 
                localVarFormParams.append('distribution', distribution as any);
            }
    
            if (product !== undefined) { 
                localVarFormParams.append('product', product as any);
            }
                if (images) {
                images.forEach((element) => {
                    localVarFormParams.append('images', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [productItemId] 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductItemsAddImagesCreate: async (productItemId?: number, images?: Array<File>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/product-items/add-images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (productItemId !== undefined) { 
                localVarFormParams.append('product_item_id', productItemId as any);
            }
                if (images) {
                images.forEach((element) => {
                    localVarFormParams.append('images', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductsProductItemsRemoveImagesCreateRequest} [productsProductItemsRemoveImagesCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductItemsRemoveImagesCreate: async (productsProductItemsRemoveImagesCreateRequest?: ProductsProductItemsRemoveImagesCreateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/products/product-items/remove-images/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(productsProductItemsRemoveImagesCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductsDestroy: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsProductsDestroy', 'id', id)
            const localVarPath = `/api/products/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedUpdateProductWithDistribution} [patchedUpdateProductWithDistribution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductsPartialUpdate: async (id: number, patchedUpdateProductWithDistribution?: PatchedUpdateProductWithDistribution, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsProductsPartialUpdate', 'id', id)
            const localVarPath = `/api/products/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUpdateProductWithDistribution, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductsRetrieve: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsProductsRetrieve', 'id', id)
            const localVarPath = `/api/products/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProductWithDistribution} [updateProductWithDistribution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductsUpdate: async (id: number, updateProductWithDistribution?: UpdateProductWithDistribution, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsProductsUpdate', 'id', id)
            const localVarPath = `/api/products/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductWithDistribution, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedUpdateProductItem} [patchedUpdateProductItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductItemPartialUpdate: async (id: number, patchedUpdateProductItem?: PatchedUpdateProductItem, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsUpdateProductItemPartialUpdate', 'id', id)
            const localVarPath = `/api/products/update-product-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchedUpdateProductItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProductItem} [updateProductItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductItemUpdate: async (id: number, updateProductItem?: UpdateProductItem, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('productsUpdateProductItemUpdate', 'id', id)
            const localVarPath = `/api/products/update-product-item/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProductItem, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProductsApi - functional programming interface
 * @export
 */
export const ProductsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProductsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [name] 
         * @param {string} [description] 
         * @param {number} [price] 
         * @param {number} [category] 
         * @param {File} [image] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCreateCreate(name?: string, description?: string, price?: number, category?: number, image?: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCreateCreate(name, description, price, category, image, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsCreateCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateDistributionRequest} createDistributionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsCreateDistributionCreate(createDistributionRequest: CreateDistributionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDistribution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsCreateDistributionCreate(createDistributionRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsCreateDistributionCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [_new] Filter for new products (optional)
         * @param {number} [page] A page number within the paginated result set.
         * @param {number} [pageSize] Number of results to return per page.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsList(_new?: boolean, page?: number, pageSize?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedProductList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsList(_new, page, pageSize, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsListDistributionsList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProductDistribution>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsListDistributionsList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsListDistributionsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [distribution] 
         * @param {number} [product] 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductItemCreate(distribution?: number, product?: number, images?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductItemCreate(distribution, product, images, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsProductItemCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [productItemId] 
         * @param {Array<File>} [images] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductItemsAddImagesCreate(productItemId?: number, images?: Array<File>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Image>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductItemsAddImagesCreate(productItemId, images, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsProductItemsAddImagesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProductsProductItemsRemoveImagesCreateRequest} [productsProductItemsRemoveImagesCreateRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductItemsRemoveImagesCreate(productsProductItemsRemoveImagesCreateRequest?: ProductsProductItemsRemoveImagesCreateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductItemsRemoveImagesCreate(productsProductItemsRemoveImagesCreateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsProductItemsRemoveImagesCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductsDestroy(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductsDestroy(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsProductsDestroy']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedUpdateProductWithDistribution} [patchedUpdateProductWithDistribution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductsPartialUpdate(id: number, patchedUpdateProductWithDistribution?: PatchedUpdateProductWithDistribution, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductsPartialUpdate(id, patchedUpdateProductWithDistribution, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsProductsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductsRetrieve(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Product>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductsRetrieve(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsProductsRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProductWithDistribution} [updateProductWithDistribution] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsProductsUpdate(id: number, updateProductWithDistribution?: UpdateProductWithDistribution, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateProductWithDistribution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsProductsUpdate(id, updateProductWithDistribution, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsProductsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PatchedUpdateProductItem} [patchedUpdateProductItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateProductItemPartialUpdate(id: number, patchedUpdateProductItem?: PatchedUpdateProductItem, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateProductItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateProductItemPartialUpdate(id, patchedUpdateProductItem, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsUpdateProductItemPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {UpdateProductItem} [updateProductItem] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async productsUpdateProductItemUpdate(id: number, updateProductItem?: UpdateProductItem, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateProductItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.productsUpdateProductItemUpdate(id, updateProductItem, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProductsApi.productsUpdateProductItemUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProductsApi - factory interface
 * @export
 */
export const ProductsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProductsApiFp(configuration)
    return {
        /**
         * 
         * @param {ProductsApiProductsCreateCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreateCreate(requestParameters: ProductsApiProductsCreateCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.productsCreateCreate(requestParameters.name, requestParameters.description, requestParameters.price, requestParameters.category, requestParameters.image, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsCreateDistributionCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsCreateDistributionCreate(requestParameters: ProductsApiProductsCreateDistributionCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductDistribution> {
            return localVarFp.productsCreateDistributionCreate(requestParameters.createDistributionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsList(requestParameters: ProductsApiProductsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PaginatedProductList> {
            return localVarFp.productsList(requestParameters._new, requestParameters.page, requestParameters.pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsListDistributionsList(options?: RawAxiosRequestConfig): AxiosPromise<Array<ProductDistribution>> {
            return localVarFp.productsListDistributionsList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsProductItemCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductItemCreate(requestParameters: ProductsApiProductsProductItemCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<ProductItem> {
            return localVarFp.productsProductItemCreate(requestParameters.distribution, requestParameters.product, requestParameters.images, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsProductItemsAddImagesCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductItemsAddImagesCreate(requestParameters: ProductsApiProductsProductItemsAddImagesCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<Array<Image>> {
            return localVarFp.productsProductItemsAddImagesCreate(requestParameters.productItemId, requestParameters.images, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsProductItemsRemoveImagesCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductItemsRemoveImagesCreate(requestParameters: ProductsApiProductsProductItemsRemoveImagesCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<boolean> {
            return localVarFp.productsProductItemsRemoveImagesCreate(requestParameters.productsProductItemsRemoveImagesCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsProductsDestroyRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductsDestroy(requestParameters: ProductsApiProductsProductsDestroyRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.productsProductsDestroy(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsProductsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductsPartialUpdate(requestParameters: ProductsApiProductsProductsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.productsProductsPartialUpdate(requestParameters.id, requestParameters.patchedUpdateProductWithDistribution, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsProductsRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductsRetrieve(requestParameters: ProductsApiProductsProductsRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<Product> {
            return localVarFp.productsProductsRetrieve(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsProductsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsProductsUpdate(requestParameters: ProductsApiProductsProductsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateProductWithDistribution> {
            return localVarFp.productsProductsUpdate(requestParameters.id, requestParameters.updateProductWithDistribution, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsUpdateProductItemPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductItemPartialUpdate(requestParameters: ProductsApiProductsUpdateProductItemPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateProductItem> {
            return localVarFp.productsUpdateProductItemPartialUpdate(requestParameters.id, requestParameters.patchedUpdateProductItem, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ProductsApiProductsUpdateProductItemUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        productsUpdateProductItemUpdate(requestParameters: ProductsApiProductsUpdateProductItemUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<UpdateProductItem> {
            return localVarFp.productsUpdateProductItemUpdate(requestParameters.id, requestParameters.updateProductItem, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for productsCreateCreate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsCreateCreateRequest
 */
export interface ProductsApiProductsCreateCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsCreateCreate
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof ProductsApiProductsCreateCreate
     */
    readonly description?: string

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsCreateCreate
     */
    readonly price?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsCreateCreate
     */
    readonly category?: number

    /**
     * 
     * @type {File}
     * @memberof ProductsApiProductsCreateCreate
     */
    readonly image?: File
}

/**
 * Request parameters for productsCreateDistributionCreate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsCreateDistributionCreateRequest
 */
export interface ProductsApiProductsCreateDistributionCreateRequest {
    /**
     * 
     * @type {CreateDistributionRequest}
     * @memberof ProductsApiProductsCreateDistributionCreate
     */
    readonly createDistributionRequest: CreateDistributionRequest
}

/**
 * Request parameters for productsList operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsListRequest
 */
export interface ProductsApiProductsListRequest {
    /**
     * Filter for new products (optional)
     * @type {boolean}
     * @memberof ProductsApiProductsList
     */
    readonly _new?: boolean

    /**
     * A page number within the paginated result set.
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly page?: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof ProductsApiProductsList
     */
    readonly pageSize?: number
}

/**
 * Request parameters for productsProductItemCreate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsProductItemCreateRequest
 */
export interface ProductsApiProductsProductItemCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsProductItemCreate
     */
    readonly distribution?: number

    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsProductItemCreate
     */
    readonly product?: number

    /**
     * 
     * @type {Array<File>}
     * @memberof ProductsApiProductsProductItemCreate
     */
    readonly images?: Array<File>
}

/**
 * Request parameters for productsProductItemsAddImagesCreate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsProductItemsAddImagesCreateRequest
 */
export interface ProductsApiProductsProductItemsAddImagesCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsProductItemsAddImagesCreate
     */
    readonly productItemId?: number

    /**
     * 
     * @type {Array<File>}
     * @memberof ProductsApiProductsProductItemsAddImagesCreate
     */
    readonly images?: Array<File>
}

/**
 * Request parameters for productsProductItemsRemoveImagesCreate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsProductItemsRemoveImagesCreateRequest
 */
export interface ProductsApiProductsProductItemsRemoveImagesCreateRequest {
    /**
     * 
     * @type {ProductsProductItemsRemoveImagesCreateRequest}
     * @memberof ProductsApiProductsProductItemsRemoveImagesCreate
     */
    readonly productsProductItemsRemoveImagesCreateRequest?: ProductsProductItemsRemoveImagesCreateRequest
}

/**
 * Request parameters for productsProductsDestroy operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsProductsDestroyRequest
 */
export interface ProductsApiProductsProductsDestroyRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsProductsDestroy
     */
    readonly id: number
}

/**
 * Request parameters for productsProductsPartialUpdate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsProductsPartialUpdateRequest
 */
export interface ProductsApiProductsProductsPartialUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsProductsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedUpdateProductWithDistribution}
     * @memberof ProductsApiProductsProductsPartialUpdate
     */
    readonly patchedUpdateProductWithDistribution?: PatchedUpdateProductWithDistribution
}

/**
 * Request parameters for productsProductsRetrieve operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsProductsRetrieveRequest
 */
export interface ProductsApiProductsProductsRetrieveRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsProductsRetrieve
     */
    readonly id: number
}

/**
 * Request parameters for productsProductsUpdate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsProductsUpdateRequest
 */
export interface ProductsApiProductsProductsUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsProductsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateProductWithDistribution}
     * @memberof ProductsApiProductsProductsUpdate
     */
    readonly updateProductWithDistribution?: UpdateProductWithDistribution
}

/**
 * Request parameters for productsUpdateProductItemPartialUpdate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsUpdateProductItemPartialUpdateRequest
 */
export interface ProductsApiProductsUpdateProductItemPartialUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsUpdateProductItemPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PatchedUpdateProductItem}
     * @memberof ProductsApiProductsUpdateProductItemPartialUpdate
     */
    readonly patchedUpdateProductItem?: PatchedUpdateProductItem
}

/**
 * Request parameters for productsUpdateProductItemUpdate operation in ProductsApi.
 * @export
 * @interface ProductsApiProductsUpdateProductItemUpdateRequest
 */
export interface ProductsApiProductsUpdateProductItemUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof ProductsApiProductsUpdateProductItemUpdate
     */
    readonly id: number

    /**
     * 
     * @type {UpdateProductItem}
     * @memberof ProductsApiProductsUpdateProductItemUpdate
     */
    readonly updateProductItem?: UpdateProductItem
}

/**
 * ProductsApi - object-oriented interface
 * @export
 * @class ProductsApi
 * @extends {BaseAPI}
 */
export class ProductsApi extends BaseAPI {
    /**
     * 
     * @param {ProductsApiProductsCreateCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsCreateCreate(requestParameters: ProductsApiProductsCreateCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsCreateCreate(requestParameters.name, requestParameters.description, requestParameters.price, requestParameters.category, requestParameters.image, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsCreateDistributionCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsCreateDistributionCreate(requestParameters: ProductsApiProductsCreateDistributionCreateRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsCreateDistributionCreate(requestParameters.createDistributionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsList(requestParameters: ProductsApiProductsListRequest = {}, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsList(requestParameters._new, requestParameters.page, requestParameters.pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsListDistributionsList(options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsListDistributionsList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsProductItemCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsProductItemCreate(requestParameters: ProductsApiProductsProductItemCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsProductItemCreate(requestParameters.distribution, requestParameters.product, requestParameters.images, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsProductItemsAddImagesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsProductItemsAddImagesCreate(requestParameters: ProductsApiProductsProductItemsAddImagesCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsProductItemsAddImagesCreate(requestParameters.productItemId, requestParameters.images, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsProductItemsRemoveImagesCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsProductItemsRemoveImagesCreate(requestParameters: ProductsApiProductsProductItemsRemoveImagesCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsProductItemsRemoveImagesCreate(requestParameters.productsProductItemsRemoveImagesCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsProductsDestroyRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsProductsDestroy(requestParameters: ProductsApiProductsProductsDestroyRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsProductsDestroy(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsProductsPartialUpdate(requestParameters: ProductsApiProductsProductsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsProductsPartialUpdate(requestParameters.id, requestParameters.patchedUpdateProductWithDistribution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsProductsRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsProductsRetrieve(requestParameters: ProductsApiProductsProductsRetrieveRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsProductsRetrieve(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsProductsUpdate(requestParameters: ProductsApiProductsProductsUpdateRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsProductsUpdate(requestParameters.id, requestParameters.updateProductWithDistribution, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsUpdateProductItemPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdateProductItemPartialUpdate(requestParameters: ProductsApiProductsUpdateProductItemPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpdateProductItemPartialUpdate(requestParameters.id, requestParameters.patchedUpdateProductItem, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ProductsApiProductsUpdateProductItemUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProductsApi
     */
    public productsUpdateProductItemUpdate(requestParameters: ProductsApiProductsUpdateProductItemUpdateRequest, options?: RawAxiosRequestConfig) {
        return ProductsApiFp(this.configuration).productsUpdateProductItemUpdate(requestParameters.id, requestParameters.updateProductItem, options).then((request) => request(this.axios, this.basePath));
    }
}

