import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { LoaderContextProps } from '../types/components';
import useLoaderStore from '../hooks/useLoaderStore';

const LoaderContext = createContext<LoaderContextProps | undefined>(undefined);

export const LoaderProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const loaderStore = useLoaderStore();
    
    // Memorizamos el valor del contexto para evitar re-renderizaciones innecesarias
    const memoizedValue = useMemo(() => loaderStore, [loaderStore.isLoading]);

    return (
        <LoaderContext.Provider value={memoizedValue}>
            {children}
        </LoaderContext.Provider>
    );
}

export const useLoader = (): LoaderContextProps => {
    const context = useContext(LoaderContext);
    if (!context) {
        throw new Error('useLoader must be used within a LoaderProvider');
    }
    return context;
};