/* tslint:disable */
/* eslint-disable */
/**
 * goal-wear
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { PromoCodeResponse } from '../models';
// @ts-ignore
import type { Publicity } from '../models';
// @ts-ignore
import type { PublicityRegisterSms } from '../models';
// @ts-ignore
import type { RegisterPhoneOrPromoCode } from '../models';
/**
 * PublicityApi - axios parameter creator
 * @export
 */
export const PublicityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Este endpoint permite registrar un número telefónico y también generar un código promocional si se envía el `is_code_promo` como `true`.
         * @summary Registrar un número telefónico o generar un código promocional
         * @param {RegisterPhoneOrPromoCode} [registerPhoneOrPromoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityCreate: async (registerPhoneOrPromoCode?: RegisterPhoneOrPromoCode, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publicity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registerPhoneOrPromoCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/publicity/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityPromoCodesRetrieve: async (code: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('publicityPromoCodesRetrieve', 'code', code)
            const localVarPath = `/api/publicity/promo-codes/{code}`
                .replace(`{${"code"}}`, encodeURIComponent(String(code)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PublicityRegisterSms} publicityRegisterSms 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityResponseSmsCreate: async (publicityRegisterSms: PublicityRegisterSms, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publicityRegisterSms' is not null or undefined
            assertParamExists('publicityResponseSmsCreate', 'publicityRegisterSms', publicityRegisterSms)
            const localVarPath = `/api/publicity/response-sms/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(publicityRegisterSms, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublicityApi - functional programming interface
 * @export
 */
export const PublicityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublicityApiAxiosParamCreator(configuration)
    return {
        /**
         * Este endpoint permite registrar un número telefónico y también generar un código promocional si se envía el `is_code_promo` como `true`.
         * @summary Registrar un número telefónico o generar un código promocional
         * @param {RegisterPhoneOrPromoCode} [registerPhoneOrPromoCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicityCreate(registerPhoneOrPromoCode?: RegisterPhoneOrPromoCode, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicityCreate(registerPhoneOrPromoCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicityApi.publicityCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicityList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Publicity>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicityList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicityApi.publicityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicityPromoCodesRetrieve(code: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoCodeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicityPromoCodesRetrieve(code, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicityApi.publicityPromoCodesRetrieve']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PublicityRegisterSms} publicityRegisterSms 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicityResponseSmsCreate(publicityRegisterSms: PublicityRegisterSms, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicityRegisterSms>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicityResponseSmsCreate(publicityRegisterSms, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PublicityApi.publicityResponseSmsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PublicityApi - factory interface
 * @export
 */
export const PublicityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublicityApiFp(configuration)
    return {
        /**
         * Este endpoint permite registrar un número telefónico y también generar un código promocional si se envía el `is_code_promo` como `true`.
         * @summary Registrar un número telefónico o generar un código promocional
         * @param {PublicityApiPublicityCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityCreate(requestParameters: PublicityApiPublicityCreateRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<PromoCodeResponse> {
            return localVarFp.publicityCreate(requestParameters.registerPhoneOrPromoCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityList(options?: RawAxiosRequestConfig): AxiosPromise<Array<Publicity>> {
            return localVarFp.publicityList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublicityApiPublicityPromoCodesRetrieveRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityPromoCodesRetrieve(requestParameters: PublicityApiPublicityPromoCodesRetrieveRequest, options?: RawAxiosRequestConfig): AxiosPromise<PromoCodeResponse> {
            return localVarFp.publicityPromoCodesRetrieve(requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PublicityApiPublicityResponseSmsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicityResponseSmsCreate(requestParameters: PublicityApiPublicityResponseSmsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicityRegisterSms> {
            return localVarFp.publicityResponseSmsCreate(requestParameters.publicityRegisterSms, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for publicityCreate operation in PublicityApi.
 * @export
 * @interface PublicityApiPublicityCreateRequest
 */
export interface PublicityApiPublicityCreateRequest {
    /**
     * 
     * @type {RegisterPhoneOrPromoCode}
     * @memberof PublicityApiPublicityCreate
     */
    readonly registerPhoneOrPromoCode?: RegisterPhoneOrPromoCode
}

/**
 * Request parameters for publicityPromoCodesRetrieve operation in PublicityApi.
 * @export
 * @interface PublicityApiPublicityPromoCodesRetrieveRequest
 */
export interface PublicityApiPublicityPromoCodesRetrieveRequest {
    /**
     * 
     * @type {string}
     * @memberof PublicityApiPublicityPromoCodesRetrieve
     */
    readonly code: string
}

/**
 * Request parameters for publicityResponseSmsCreate operation in PublicityApi.
 * @export
 * @interface PublicityApiPublicityResponseSmsCreateRequest
 */
export interface PublicityApiPublicityResponseSmsCreateRequest {
    /**
     * 
     * @type {PublicityRegisterSms}
     * @memberof PublicityApiPublicityResponseSmsCreate
     */
    readonly publicityRegisterSms: PublicityRegisterSms
}

/**
 * PublicityApi - object-oriented interface
 * @export
 * @class PublicityApi
 * @extends {BaseAPI}
 */
export class PublicityApi extends BaseAPI {
    /**
     * Este endpoint permite registrar un número telefónico y también generar un código promocional si se envía el `is_code_promo` como `true`.
     * @summary Registrar un número telefónico o generar un código promocional
     * @param {PublicityApiPublicityCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicityApi
     */
    public publicityCreate(requestParameters: PublicityApiPublicityCreateRequest = {}, options?: RawAxiosRequestConfig) {
        return PublicityApiFp(this.configuration).publicityCreate(requestParameters.registerPhoneOrPromoCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicityApi
     */
    public publicityList(options?: RawAxiosRequestConfig) {
        return PublicityApiFp(this.configuration).publicityList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicityApiPublicityPromoCodesRetrieveRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicityApi
     */
    public publicityPromoCodesRetrieve(requestParameters: PublicityApiPublicityPromoCodesRetrieveRequest, options?: RawAxiosRequestConfig) {
        return PublicityApiFp(this.configuration).publicityPromoCodesRetrieve(requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PublicityApiPublicityResponseSmsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublicityApi
     */
    public publicityResponseSmsCreate(requestParameters: PublicityApiPublicityResponseSmsCreateRequest, options?: RawAxiosRequestConfig) {
        return PublicityApiFp(this.configuration).publicityResponseSmsCreate(requestParameters.publicityRegisterSms, options).then((request) => request(this.axios, this.basePath));
    }
}

