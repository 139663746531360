/* tslint:disable */
/* eslint-disable */
/**
 * goal-wear
 * API Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AddressSearchRequest } from '../models';
// @ts-ignore
import type { AddressSearchResponse } from '../models';
// @ts-ignore
import type { ParcelRequest } from '../models';
// @ts-ignore
import type { ParcelResponse } from '../models';
// @ts-ignore
import type { ShippingRatesRequest } from '../models';
// @ts-ignore
import type { ShippingResponse } from '../models';
/**
 * ShippingApi - axios parameter creator
 * @export
 */
export const ShippingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Validar dirección
         * @param {AddressSearchRequest} addressSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingAddressSearchCreate: async (addressSearchRequest: AddressSearchRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addressSearchRequest' is not null or undefined
            assertParamExists('shippingAddressSearchCreate', 'addressSearchRequest', addressSearchRequest)
            const localVarPath = `/api/shipping/address/search/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addressSearchRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Crear paquetes
         * @summary Crear paquetes
         * @param {ParcelRequest} parcelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingParcelsCreate: async (parcelRequest: ParcelRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'parcelRequest' is not null or undefined
            assertParamExists('shippingParcelsCreate', 'parcelRequest', parcelRequest)
            const localVarPath = `/api/shipping/parcels/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(parcelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Crear Shipping
         * @summary Crear Shipping
         * @param {ShippingRatesRequest} shippingRatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingShippingCreate: async (shippingRatesRequest: ShippingRatesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shippingRatesRequest' is not null or undefined
            assertParamExists('shippingShippingCreate', 'shippingRatesRequest', shippingRatesRequest)
            const localVarPath = `/api/shipping/shipping/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication jwtAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shippingRatesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShippingApi - functional programming interface
 * @export
 */
export const ShippingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShippingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Validar dirección
         * @param {AddressSearchRequest} addressSearchRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingAddressSearchCreate(addressSearchRequest: AddressSearchRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AddressSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingAddressSearchCreate(addressSearchRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingApi.shippingAddressSearchCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Crear paquetes
         * @summary Crear paquetes
         * @param {ParcelRequest} parcelRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingParcelsCreate(parcelRequest: ParcelRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ParcelResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingParcelsCreate(parcelRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingApi.shippingParcelsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Crear Shipping
         * @summary Crear Shipping
         * @param {ShippingRatesRequest} shippingRatesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async shippingShippingCreate(shippingRatesRequest: ShippingRatesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShippingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.shippingShippingCreate(shippingRatesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShippingApi.shippingShippingCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShippingApi - factory interface
 * @export
 */
export const ShippingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShippingApiFp(configuration)
    return {
        /**
         * 
         * @summary Validar dirección
         * @param {ShippingApiShippingAddressSearchCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingAddressSearchCreate(requestParameters: ShippingApiShippingAddressSearchCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AddressSearchResponse> {
            return localVarFp.shippingAddressSearchCreate(requestParameters.addressSearchRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Crear paquetes
         * @summary Crear paquetes
         * @param {ShippingApiShippingParcelsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingParcelsCreate(requestParameters: ShippingApiShippingParcelsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ParcelResponse> {
            return localVarFp.shippingParcelsCreate(requestParameters.parcelRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Crear Shipping
         * @summary Crear Shipping
         * @param {ShippingApiShippingShippingCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        shippingShippingCreate(requestParameters: ShippingApiShippingShippingCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ShippingResponse> {
            return localVarFp.shippingShippingCreate(requestParameters.shippingRatesRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for shippingAddressSearchCreate operation in ShippingApi.
 * @export
 * @interface ShippingApiShippingAddressSearchCreateRequest
 */
export interface ShippingApiShippingAddressSearchCreateRequest {
    /**
     * 
     * @type {AddressSearchRequest}
     * @memberof ShippingApiShippingAddressSearchCreate
     */
    readonly addressSearchRequest: AddressSearchRequest
}

/**
 * Request parameters for shippingParcelsCreate operation in ShippingApi.
 * @export
 * @interface ShippingApiShippingParcelsCreateRequest
 */
export interface ShippingApiShippingParcelsCreateRequest {
    /**
     * 
     * @type {ParcelRequest}
     * @memberof ShippingApiShippingParcelsCreate
     */
    readonly parcelRequest: ParcelRequest
}

/**
 * Request parameters for shippingShippingCreate operation in ShippingApi.
 * @export
 * @interface ShippingApiShippingShippingCreateRequest
 */
export interface ShippingApiShippingShippingCreateRequest {
    /**
     * 
     * @type {ShippingRatesRequest}
     * @memberof ShippingApiShippingShippingCreate
     */
    readonly shippingRatesRequest: ShippingRatesRequest
}

/**
 * ShippingApi - object-oriented interface
 * @export
 * @class ShippingApi
 * @extends {BaseAPI}
 */
export class ShippingApi extends BaseAPI {
    /**
     * 
     * @summary Validar dirección
     * @param {ShippingApiShippingAddressSearchCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingApi
     */
    public shippingAddressSearchCreate(requestParameters: ShippingApiShippingAddressSearchCreateRequest, options?: RawAxiosRequestConfig) {
        return ShippingApiFp(this.configuration).shippingAddressSearchCreate(requestParameters.addressSearchRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Crear paquetes
     * @summary Crear paquetes
     * @param {ShippingApiShippingParcelsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingApi
     */
    public shippingParcelsCreate(requestParameters: ShippingApiShippingParcelsCreateRequest, options?: RawAxiosRequestConfig) {
        return ShippingApiFp(this.configuration).shippingParcelsCreate(requestParameters.parcelRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Crear Shipping
     * @summary Crear Shipping
     * @param {ShippingApiShippingShippingCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShippingApi
     */
    public shippingShippingCreate(requestParameters: ShippingApiShippingShippingCreateRequest, options?: RawAxiosRequestConfig) {
        return ShippingApiFp(this.configuration).shippingShippingCreate(requestParameters.shippingRatesRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

