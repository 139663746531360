import { ProductsResponse } from "../types/apiResponse";
import axiosInstance from "./axios";
import { productsApi  } from '../api'
import { PaginatedProductList } from '../api-client'



export const fetchProducts = async (news: boolean): Promise<PaginatedProductList> => {
    try {
        const {data} = await productsApi.productsList({_new : news ?? false});
        return data;
    } catch (error) {
        console.error("Error fetching products: ", error);
        throw error;
    }
}
