// src/App.tsx
import './App.css';
import React, { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useAxiosInterceptors } from './hooks/useAxiosInterceptor';
import { LoaderProvider } from './context/LoaderContext';
import GlobalLoader from './components/Loader/GlobalLoader';
import SuspenseLoader from './components/Loader/SuspenseLoader';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useAuthStore } from './store/AuthStore';
import ScrollToTop from './components/ScrollTop/ScrollTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useProducts } from './hooks/useProducts';

// Lazy loading de componentes
const Layout = lazy(() => import('./layouts/Layout'));
const Home = lazy(() => import('./screens/Client/Home/Home'));
const Product = lazy(() => import('./screens/Client/ProductDetail/Product'));
const ShoppingPayment = lazy(() => import('./screens/Client/ShoppingPayment/ShoppingPayment'));
const Signin = lazy(() => import('./screens/Client/Signin/Signin'));
const Signup = lazy(() => import('./screens/Client/Signup/Signup'));
const Category = lazy(() => import('./screens/Client/Category/Category'));
const Notification = lazy(() => import('./screens/Client/Featured/Featured'));
const UserProfile = lazy(() => import('./screens/Client/Profile/UserProfile'));


function App() {
  const { products } = useProducts(true);

  useAxiosInterceptors();
  const clientId = process.env.REACT_APP_PAYPAL_CLIENT_ID || "tu_client_id_por_defecto";

  //productions
   const initialOptions = {
   clientId: clientId || "test", // Usa "test" como valor por defecto para desarrollo
     currency: "USD",
     intent: "capture",
   };




  const initializeFromStorage = useAuthStore(state => state.initializeFromStorage);
  // Carga el usuario si ya tiene iniciado sesión
  useEffect(() => {
    initializeFromStorage();
  }, [initializeFromStorage]);

  return (
    <PayPalScriptProvider options={initialOptions}>
      <BrowserRouter>
        {/* Loader Global */}
        {/* <GlobalLoader /> */}

        {/* Suspense para carga de componentes lazy */}
        <Suspense fallback={<SuspenseLoader />}>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Navigate to="/home" />}/>
            <Route path="/home" element={<Layout FeaturedFooter={products?.results?.length || 0}><Home /></Layout>}/>
            <Route path="/product-detail/:id" element={<Layout FeaturedFooter={products?.results?.length || 0}><Product /></Layout>}/>
            <Route path="/shopping-payment" element={<Layout FeaturedFooter={products?.results?.length || 0}><ShoppingPayment /></Layout>}/>
            <Route path="/category" element={<Layout FeaturedFooter={products?.results?.length || 0}><Category /></Layout>} />
            <Route path="/featured" element={<Layout FeaturedFooter={products?.results?.length || 0}>{products && <Notification products={products}/>}</Layout>} />
            <Route path="/sign-in" element={<Signin />}/>
            <Route path="/sign-up" element={<Signup />}/>
            <Route path="/profile" element={<Layout FeaturedFooter={products?.results?.length || 0}><UserProfile/></Layout>}/>
          </Routes>
        </Suspense>

        <ToastContainer 
          position="top-right"
          autoClose={1500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </BrowserRouter>
    </PayPalScriptProvider>
  );
};

// Envolver App con LoaderProvider
const WrappedApp: React.FC = () => (
  <LoaderProvider>
    <App />
  </LoaderProvider>
);

export default WrappedApp;
