// src/components/Loader/SuspenseLoader.tsx
import React from 'react';
import EnhancedSpinner from '../Spinner/EnhancedSpinner';

const SuspenseLoader: React.FC = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
            <EnhancedSpinner/>
        </div>
    );
};

export default SuspenseLoader;
