import { useState, useCallback } from 'react';

const useLoaderStore = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(0);

  const setLoading = useCallback((payload: any) => {
    if (payload) {
      setCount((prevCount) => prevCount + 1);
      setIsLoading(true);
    } else {
      setCount((prevCount) => {
        const newCount = prevCount > 0 ? prevCount - 1 : 0;
        setIsLoading(newCount > 0);
        return newCount;
      });
    }
  }, []);

  return {
    isLoading,
    count,
    setLoading,
  };
};

export default useLoaderStore;