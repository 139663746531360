// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import './css/global.css';
import WrappedApp from './App';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <WrappedApp />
  </React.StrictMode>
);

// Carga asíncrona de reportWebVitals
import('./reportWebVitals').then(({ default: reportWebVitals }) => {
  reportWebVitals();
});
